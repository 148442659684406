

// shop single area start

.shop-single{
    .rts-btn{
        border-radius: 0;
        &::before{
            border-radius: 0;
        }
    }
}


.blog-single-two-wrapper {
    border: 1px solid #E9E9E9;
}
.blog-single-two-wrapper .image-area {
    position: relative;
}
.blog-single-two-wrapper .image-area .thumbnail {
    overflow: hidden;
    display: block;
}
.blog-single-two-wrapper .image-area .thumbnail img {
    width: 100%;
    transition: 1.3s;
}
.blog-single-two-wrapper .inner {
    padding: 40px;
    margin-top: -9px;
}
.blog-single-two-wrapper.shop .inner .pre-tag {
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: var(--color-primary);
    margin-top: -5px;
}
.blog-single-two-wrapper .inner .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-top: 5px;
    transition: 0.3s;
}
.blog-single-two-wrapper .inner a.btn-read-more-blog {
    color: var(--color-primary);
    align-items: center;
}
.blog-single-two-wrapper .inner a.btn-read-more-blog i {
    margin-left: 5px;
    position: relative;
}

.blog-single-two-wrapper .image-area .thumbnail:hover img {
    transform: scale(1.15);
}