
// header style hear

header.header-one{
    background: #F2F2F2;
    position: relative;
    .header--one-main{
        position: relative;
        .logo-area{
            padding: 40px 10px 40px 0;
            display: block;
        }
        .header-right-area{
            display: flex;
            justify-content: flex-end;
            .single-info-contact{
                display: flex;
                align-items: center;
                i{
                    width: 57px;
                    height: 57px;
                    border-radius: 50%;
                    background: #fff;
                    box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.03);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    color: var(--color-primary);
                }
                .inner-content{
                    margin-left: 20px;
                    span{
                        display: block;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #74787C;
                    }
                    .title{
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        margin-top: 6px;
                        transition: .3s;
                        min-width: max-content;
                        @media #{$smlg-device} {
                            font-size: 15px;
                        }
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
                &.map{
                    margin-left: 40px;
                    padding-left: 40px;
                    border-left: 1px solid #D9D9D9;
                    @media #{$smlg-device} {
                        margin-left: 20px;
                        padding-left: 20px;
                        border-left: 1px solid #D9D9D9;
                    }
                }
            }
            
            .btn-area-header{
                display: flex;
                align-items: center;
                margin-left: 60px;
                @media #{$smlg-device} {
                    margin-left: 20px;
                }
            }
        }
    }
}

.header-top-one{
    @media #{$md-layout} {
        display: none;
    }
    @media #{$sm-layout} {
        display: none;
    }
}

.logo-md-sm-device{
    display: none;
    @media #{$sm-layout} {
        display: block;
    }
    .logo{
        img{
            max-width: 120px;
        }
    }
}




.header-two-solari{
    &.header-top{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--color-primary);
        @media #{$smlg-device} {
            display: none;
        }
        .header-top-m{
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: var(--color-primary);
            .left{
                display: flex;
                align-items: center;
                gap: 36px;
                .inf{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    i{
                        color: #fff;
                    }
                    p{
                        color: #fff;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                    }
                    a{
                        color: #fff;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
            .right{

            }
        }
    }
}

.social-header-top-h2{
    display: flex;
    align-items: center;
    span{
        color: #fff;
    }
    ul{
        padding: 0;
        margin: 0;
        
        margin-left: 15px;
        display: flex;
        align-items: center;
        gap: 20px;
        list-style: none;
        li{
            margin: 0;
            a{
                margin: 0;
                i{
                    color: #fff;
                    transition: .3s;
                }
                &:hover{
                    i{
                        color: #1f1f25;
                    }
                }
            }
        }
    }
}

.main-haeder-wrapper-h2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$large-mobile} {
        padding: 0 10px;
    }
    a.logo-area{
        padding: 23px 0;
        display: block;
        @media #{$sm-layout} {
            padding: 10px 0;
        }
        img{
            @media #{$md-layout} {
                max-width: 140px;
            }
            @media #{$sm-layout} {
                max-width: 120px;
            }
        }
    }
    .actions-area{
        display: flex;
        align-items: center;
        a.rts-btn{
            padding: 13px 25px;
            margin-left: 30px;
            @media #{$smlg-device} {
                display: none;
            }
        }
        .menu-btn{
            cursor: pointer;
            margin-left: 30px;
        }
        .search-btn{
            cursor: pointer;
            i{
                color: #000;
            }
        }
        .cart{
            margin-left: 30px;
            i{
                color: var(--color-primary);
                font-size: 22px;
                position: relative;
                margin-top: 8px !important;
                cursor: pointer;
                &::after{
                    position: absolute;
                    content: "2";
                    right: -8px;
                    bottom: 14px;
                    height: 20px;
                    width: 20px;
                    background: var(--color-primary);
                    border-radius: 50%;
                    font-size: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                }
            }
        }
    }
}

#menu-btn{
    cursor: pointer;
}

header.header-three{
    padding: 32px 80px;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
    @media #{$smlg-device} {
        padding: 32px 20px;
    }
    .header-left{
        display: flex;
        align-items: center;
        gap: 50px;
        .logo-area{
            img{
                @media #{$sm-layout} {
                    max-width: 120px;
                }
            }
        }
    }
    .header-right{
        .action-button-menu{
            display: flex;
            align-items: center;
            gap: 30px;
            a.contact{
                cursor: pointer;
                color: #fff;
                font-weight: 700;
                font-size: 16px;
                line-height: 26px;
                transition: .3s;
                &:hover{
                    color: var(--color-primary);
                }
            }
            #menu-btn{
                svg{
                    rect{
                        transition: .3s;
                    }
                }
                &:hover{
                    svg{
                        rect{
                            fill: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}

header.header-three.header--sticky.sticky .header-right .action-button-menu #menu-btn svg rect{
    fill: var(--color-primary);
}
header.header-three.sticky .header-right .action-button-menu a.contact{
    color: var(--color-primary);
}


.onepage{
    .main-nav-one ul li a.nav-link{
        margin: 0 10px;
    }
}


.index-two.onepage{
    input{
        border-radius: 5px;
    }
    textarea{
        border-radius: 5px;
    }
}

.header-three.sticky{
    a.logo-area{
        padding: 15px 0;
    }
}




header.header-four{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    z-index: 5;
    padding: 20px 80px;
    @media #{$smlg-device} {
        padding: 20px 40px;
    }
    @media #{$sm-layout} {
        padding: 20px;
    }
    .header-left{
        .logo-area{
            img{
                @media #{$md-layout} {
                    max-width: 120px;
                }
                @media #{$sm-layout} {
                    max-width: 120px;
                }
            }
        }
    }
    .nav-area{
        .main-nav-one ul li.has-dropdown a.nav-link{
            color: #fff;
        }
        .main-nav-one ul li{
            a.nav-link{
                color: #fff;
            }
        }
    }
    .header-right{
        .action-button-menu{
            display: flex;
            align-items: center;
            .contact{
                color: #fff;
            }
            #menu-btn{
                margin-left: 25px;
            }
        }
    }
}



.about-area-solari-3-right{
    .solari-title-area-four{
        text-align: left;
        .pre-title{
            text-align: left;
            margin-right: auto;
            margin-left: 0;
            position: relative;
        }
    }
}

.solari-title-area-four span.pre-title::after {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    background-image: url(../images/banner/shape/03.png);
    height: 7px;
    width: 48px;
    background-size: contain;
    background-repeat: no-repeat;
}


.solari-title-area-four span.pre-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--color-primary);
    display: block;
    margin-bottom: 16px;
    padding-left: 60px;
    position: relative;
    max-width: max-content;
    margin-bottom: 16px;
}
.solari-title-area-four .pre-title {
    text-align: left;
    margin-right: auto;
    margin-left: 0;
}
.solari-title-area-four .title {

    @media #{$smlg-device} {
        br{
            display: none;
        }
    }
}

.header-four.header--sticky.sticky{
    background: #1B1B1B;
}


.draw{
    position: relative;
    svg{
        position: absolute;
        left: 84px;
        bottom: -2px;
        @media #{$large-mobile} {
            position: absolute;
            left: 0;
            bottom: -13px;
        }
    }
    path {
        animation: progress-draw 5s linear forwards;
      }
      @keyframes progress-draw {
        from {
          stroke-dasharray: 0 1000;
        }
        to {
          stroke-dasharray: 1000;
        }
    }
}

.title-shop-main-center{
    .title{
        .draw{
            svg{
                left: 40px;
                bottom: -4px;
                @media #{$small-mobile} {
                    left: -46px;
                    bottom: -17px;
                }
            }
        }
    }
}

.single-shopping-product{
    &:hover{
        .invisible-btn{
            transform: translateX(-50%) scale(1);
        }
    }

}


.banner-area-four-wrapper-swiper-main{
    position: relative;
    .circle-animation{
        position: absolute;
        right: 80px;
        bottom: 100px;
        z-index: 10;
        border-radius: 50%;
        border: 1px solid #74787C;
        @media #{$large-mobile} {
            display: none;
        }
        i{
            position: absolute;
            color: #fff;
            left: 42%;
            top: 42%;
            font-size: 24px;
        }
    }
    .uni-circle-text::before {
        content: "";
        display: block;
        width: 78px;
        height: 78px;
        border: 1px solid rgba(245, 245, 245, 0);
        position: absolute;
        transform: scale(.5);
        left: 0;
        top: 0;
        transform: translate(20px,20px);
        border-radius: 100%;
    }
    .uni-circle-text-path {
        fill: #fff;
        height: auto;
        max-width: 132px;
        right: 10%;
        transform-origin: center;
        text-transform: uppercase;
    }
    .uni-animation-spin {
        display: inline-flex;
        animation: spin 10s linear 0s infinite;
    }
    @keyframes spin {
        0% {
            transform: rotate(0)
        }
    
        50% {
            transform: rotate(180deg)
        }
    
        100% {
            transform: rotate(360deg)
        }
    }
}

.onepage{
    .main-nav-one ul li.has-dropdown a::before {
        display: none;
    }
}