/*=================Scss Indexing=============
1.variables
2.typography
3.spacing
4.reset
5.forms
6.mixins
7.shortcode
8.animations
9.text-animation
10.sal
11.header
12.mobile-menu
13.button
14.nav
15.banner
16.swiper
17.funfacts
18.cta
19.about
20.common
21.service
22.projects
23.working-process
24.blog
25.blog-details
26.footer
27.search-input
28./side-bar
29.team
30.testimonials
31.faq
32.pricing
33.date-picker
34.time-picker
35.appoinment
36.awesome-feedback
37.contact
38.pre-loader.scss
39.back-to-top



==============================================  */


/* Default  */

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/animations';
@import'default/text-animation';
@import'default/sal';

/* elements */
@import'elements/header';
@import'elements/mobile-menu';
@import'elements/button';
@import'elements/nav';
@import'elements/banner';
@import'elements/swiper';
@import'elements/funfacts';
@import'elements/cta';
@import'elements/about';
@import'elements/common';
@import'elements/service';
@import'elements/projects';
@import'elements/working-process';
@import'elements/blog';
@import'elements/blog-details';
@import'elements/footer';
@import'elements/search-input';
@import'elements/side-bar';
@import'elements/team';
@import'elements/testimonials';
@import'elements/faq';
@import'elements/pricing';
@import'elements/date-picker';
@import'elements/time-picker';
@import'elements/appoinment';
@import'elements/awesome-feedback';
@import'elements/contact';
@import'elements/feature-item';
@import'elements/pre-loader.scss';
@import'elements/checkout';
@import'elements/shop-single';
@import'elements/cart-bar';
@import'elements/back-to-top';






