
.mySwiper-h-2-c{
    .swiper-pagination-h2-c{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        .swiper-pagination-bullet{
            width: 6px;
            height: 6px;
            background: #BBBBBB;
            cursor: pointer;
            opacity: 1;
            position: relative;
            z-index: 5;
        }
        .swiper-pagination-bullet-active{
            width: 10px;
            height: 10px;
            background: var(--color-primary);
            cursor: pointer;
            opacity: 1;
            position: relative;
            z-index: 5;
        }
    }
}