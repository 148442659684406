// mobile menu active

#side-bar{
    .inner-main-wrapper-desk{
        display: block;
        margin-top: 50px;
        @media #{$md-layout} {
            display: none;
        }
        @media #{$sm-layout} {
            display: none;
        }
    }
    .mobile-menu-main{
        display: none;
        @media #{$md-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: block;
        }
    }
}


// mobile menu scss

.mobile-menu-main{
    nav{
        ul{
            padding: 0 20px;
            display: block;
            li{
                margin: 0;
                padding: 0;
                a.main{
                    padding: 12px 0 17px 0;
                    border-bottom: 1px solid #f3f3f3;
                    cursor: pointer;
                }
                &.has-droupdown{
                    position: relative;
                    ul{
                        padding: 0;
                        a{
                            padding: 10px 0;
                        }
                        a.tag{
                            font-weight: 700;
                            margin-top: 0;
                            font-size: 18px;
                            border-bottom: 2px solid var(--color-primary);
                            padding: 10px 0;
                        }
                        li{
                            margin: 0px 0 !important;
                            border-bottom: 1px solid #f3f3f3;
                        }
                    }
                    &::after{
                        position: absolute;
                        content: '\f078';
                        font-family: 'Font Awesome 6 pro' !important;
                        font-size: 16px;
                        right: 0;
                        font-weight: 400;
                        top: 5px;
                        padding: 8px 13px;
                        color: #fff;
                        background: var(--color-primary) !important;
                        pointer-events: none;
                        cursor: pointer;
                    }
                    &.mm-active{
                        &::after{
                            content: '\f077';
                        }
                    }
                    &.third-lvl{
                        &::after{
                            font-size: 10px;
                            padding: 3px 10px;
                        }
                        ul{
                            padding: 0 20px;
                            li{
                                margin: 10px 0 !important;
                                position: relative;
                                z-index: 1;
                                transition: all .3s;
                                &:hover{
                                        color: var(--color-primary);
                                }
                                a{
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    transition: all .3s;
                                }
                            }
                        }
                    }
                }
                a{
                    display: block;
                }
            }
        }
    }
    .social-wrapper-one{
        margin-top: 50px;
    }
}


// header-two menu are-start
.side-bar{
    &.header-two,
    &.header-three{
        .inner-main-wrapper-desk{
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
        .mobile-menu{
            @media screen and (min-width: 1024px) {
                display: none !important;
            }
        }
    }
}

.header-one .main-nav-desk nav ul li.has-droupdown .submenu.inner-page{
    .sub-dropdown{
        position: relative;
        &:hover{
            &::after{
                color: var(--color-primary);
            }
        }
        &::after{
            position: absolute;
            content: '\f105';
            font-family: "Font Awesome 6 Pro" !important;
            font-size: 16px;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
        }
        .third-lvl{
            margin-left: -4px;
        }
    }
}
.header-three .main-nav-desk nav ul li.has-droupdown .submenu.inner-page{
    padding: 15px 0;
    .sub-dropdown{
        position: relative;
        &:hover{
            &::after{
                color: var(--color-primary);
            }
        }
        &::after{
            position: absolute;
            content: '\f105';
            font-family: "Font Awesome 6 Pro" !important;
            font-size: 16px;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
        }
        .third-lvl{
            margin-left: -4px;
        }
    }
    li{
        margin: 0;
        width: 100%;
        a{
            display: block;
            width: 100%;
            padding: 0 15px;
        }
    }
}


.sub-dropdown{
    position: relative !important;
    display: block !important;

    &::after{
        position: absolute;
        content: '>';
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #000;
        font-size: 22px;
    }
    .submenu.third-lvl.base{
        opacity: 0 !important;
        min-width: 185px !important;
        left: 100% !important;
        top: -13% !important;
        margin: 0;
        border-radius: 0 !important;
        a{
            border-bottom: 1px solid #ebebeb !important;
        }
        &.base{
            display: block !important;
        }
    }
    &:hover{
        .sub-menu-link{
            color: var(--color-primary);
        }
        .submenu.third-lvl.base{
            opacity: 1!important;
            min-width: max-content !important;
            top: 0 !important;
            right: 3px;
            display: block !important;
            border-radius: 10px !important;
            li{
                display: block;
                a{
                    display: block !important;
                }
            }
        }
    }
}

.header-three{
    .sub-dropdown:hover .submenu.third-lvl.base{
        margin-left: -14px !important;
    }
}
.header-two .header-main-wrapper{
    .sub-dropdown:hover .submenu.third-lvl.base{
        margin-left: 90px !important;
    }
}

header.heder-two{
    .sub-dropdown:hover .submenu.third-lvl.base{
        opacity: 1 !important;
        min-width: 185px !important;
        top: 0 !important;
        right: 3px;
        display: block;
        margin-left: 0 !important;
    }
    .sub-dropdown{
        &::after{
            position: absolute;
            content: '\f105';
            font-family: "Font Awesome 6 Pro" !important;
            font-size: 16px;
            right: 20px;
            top: 8px;
            color: #fff;
        }
        &:hover{
            a.sub-menu-link{
                color: var(--color-primary) !important;
            }
            &::after{
                color: var(--color-primary) !important;
            }
        }
    }
    .sub-dropdown .submenu.third-lvl.base{
        display: block;
    }
}
header.header-three{
    .sub-dropdown:hover .submenu.third-lvl.base{
        opacity: 1 !important;
        min-width: 185px !important;
        top: 0 !important;
        right: 3px;
        display: block;
        margin-left: 0 !important;
    }
    .sub-dropdown{
        &::after{
            position: absolute;
            content: '\f105';
            font-family: "Font Awesome 6 Pro" !important;
            font-size: 16px;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
        }
        &:hover{
            a.sub-menu-link{
                color: var(--color-primary) !important;
            }
            &::after{
                color: var(--color-primary) !important;
            }
        }
    }
    .sub-dropdown .submenu.third-lvl.base{
        display: block;
    }
}

header{
    &.header-three{
        
    }
}
